.editors-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem auto;
}

.editor-wrapper {
    width: 768px;
    border: 1px solid black;
    background: #fff;
    padding: 1rem;
}

.toolbar {
    padding-bottom: 20px;
}

.editor-container {
    width: 100%;
    border: 1px solid green;
    padding: 1rem;
    background-color: #07111a;
}

.toolbar-grid {
    display: flex;
    flex-wrap: wrap;
    flex-basis: 1fr 1fr;
}

.editors-container button {
    margin-right: 8px;
    font-size: 1rem;
    padding: 10px;
    border: none;
    background: #07111a;
    cursor: pointer;
}

.superFancyBlockquote {
    color: #999;
    background: #fff;
    font-family: "Hoefler Text", Georgia, serif;
    font-style: italic;
    border-left: 2px solid #999;
    padding-left: 10px;
}

.codeBlock {
    font-family: fira-code, monospace;
    font-size: inherit;
    background: #464646;
    font-style: italic;
    word-wrap: normal;
    word-wrap: break-word;
    box-decoration-break: slice;
    padding: 0.3rem 2rem;
    border-radius: 0.2rem;
}

.leftAlign {
    text-align: left;
}

.rightAlign {
    text-align: right;
}

.centerAlign {
    text-align: center;
}

.justifyAlign {
    text-align: justify;
}