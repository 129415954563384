#popup-container {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 50;
    background-color: #a685ff48;
    backdrop-filter: blur(10px);
}

.popup-model {
    background-color: #07111A;
    width: 80%;
    /* height: 80%; */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 15px;
}

.popup-model>div {
    padding: 30px 5px 30px;
}

.close-icon {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 35px;
    cursor: pointer;
}

@media only screen and (max-width: 1200px) {}

@media only screen and (max-width: 992px) {}

@media only screen and (max-width: 768px) {
    .popup-model {
        width: 100%;

    }
}

@media only screen and (max-width: 600px) {}

@media only screen and (max-width: 480px) {}