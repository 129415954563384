.profile-page-container {
    width: 100%;
    margin: 80px auto 0;
    background-color: #44a8ff1a;
    padding: 15px;
    border-radius: 15px;

    position: relative;

    display: flex;
    gap: 30px;
}

.profile-page-container .top {
    flex: 0 0 30%;
    display: flex;
    gap: 30px;
    padding-bottom: 80px;
    flex-direction: column;
    position: relative;
}

.profile-page-container .top>div {
    position: relative;
}

#account-type {
    position: absolute;
    bottom: -50px;
    right: -50px;
    z-index: 15;
}

#account-type>img {
    width: 120px;
}

.profile-page-container .middle {
    gap: 30px;
    width: 100%;
    flex: 1 1;
    margin-top: 20px;
}

.profile-page-container .middle>div:first-child {
    flex: 0 0 30%;
}

.profile-page-container .middle>div:last-child {
    width: 100%;
}

.profile-page-container .middle::-webkit-scrollbar {
    width: 0;
    /* Remove scrollbar space */
    background: transparent;
    /* Optional: just make scrollbar invisible */
}

/* Optional: show position indicator in red */
.profile-page-container .middle::-webkit-scrollbar-thumb {
    background: #FF0000;
}

.profile-page-container .info-container {
    margin: 20px auto 50px;
}

.profile-page-container .info-container h6 {
    color: rgb(189, 189, 189);
}

.profile-page-container .info-container>div {
    margin: 10px auto;
    padding: 8px;
    border-radius: 5px;
    background-color: rgba(212, 212, 212, 0.062);
}

.profile-page-container h2 {
    font-size: 50px;
    font-weight: 800;
    background: -webkit-linear-gradient(left, var(--color-pink), var(--color-orange));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

#profile-img {
    width: 350px;
    height: 350px;
    border-radius: 15px;
    text-align: center;
    display: block;
    z-index: 10;
    border: 8px solid var(--color-pink);
    position: relative;
    object-fit: cover;
}

select {
    width: 100%;
    border: none;
    border-radius: 5px;
    padding: 5px;
    width: 100%;
    font-size: 22px;
    margin-right: 10px;
    padding: 12px;
    border-radius: 15px;
}

.color-container {
    position: absolute;
    top: 30px;
    right: 30px;
    z-index: 12;

}

.current-color {
    width: 40px;
    height: 40px;
    border-radius: 3px;
    border: 3px solid #fff;
    background-color: var(--color-purple);
}

.color-box {
    margin-top: 10px;
    gap: 10px;

    display: none;
}

.color-option {
    width: 40px;
    height: 40px;
    border-radius: 3px;
    border: 3px solid #fff;
    margin: 5px 0;
}

#color-1 {
    background-color: #FBB924;
}

#color-2 {
    background-color: #FF2E56;
}

#color-3 {
    background-color: #F37F2C;
}

#color-4 {
    background-color: #5E58EC;
}

#color-5 {
    background-color: #05A2E9;
}

#color-6 {
    background-color: #F3432C;
}

.color-container:hover .color-box {
    display: block;
}

@media only screen and (max-width: 1200px) {}

@media only screen and (max-width: 992px) {}

@media only screen and (max-width: 768px) {
    .profile-page-container .top {

        flex-direction: column;
        align-items: center;
    }

    .profile-page-container h2 {
        font-size: 42px;
    }

    .profile-page-container .middle {
        flex-direction: column;
    }

    #profile-img {
        width: 300px;
        height: 300px;
    }

    .profile-page-container {

        margin: 30px auto;
        background-color: transparent;
        padding: 5px 15px;

        position: relative;
        flex-direction: column;
    }

    .color-container {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 12;

    }
}

@media only screen and (max-width: 480px) {}

@media only screen and (max-width: 600px) {}